<template>
  <v-tab-item>
    <v-card v-if="orders">
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card elevation="0">
              <v-card-title class="pt-0"
                >{{ type }} Orders
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search3"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="filteredOrders"
                :search="search3"
              >
                <template v-slot:item.image="{ item }">
                  <v-dialog :id="'image' + item.id" width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="error"
                        class="my-1"
                        small
                        dark
                      >
                        See Image
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>Featured Image</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="py-2">
                        <v-img
                          :src="
                            'https://dysgeklraeqfx.cloudfront.net/products/' +
                              item.pslug +
                              '/featured/' +
                              item.img
                          "
                        ></v-img>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary-color" text @click="close">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex justify-left align-items-center">
                    <div v-if="type === 'Pending'" class="my-2">
                      <v-btn
                        color="success"
                        class="my-2"
                        v-if="item.status === 'Confirmed'"
                        small
                        dark
                        @click="updateStatus(item.id, 'Ready')"
                      >
                        Ready
                      </v-btn>
                      <v-btn color="success" class="my-2" v-else small dark>
                        {{ item.status }}
                      </v-btn>
                    </div>
                    <div v-else-if="type === 'Active'" class="my-2">
                      <v-btn
                        color="success"
                        class="my-1"
                        small
                        dark
                        @click="updateStatus(item.id, 'Confirmed')"
                      >
                        Confirm
                      </v-btn>
                      <v-dialog :id="'rejectDialog' + item.id" width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="error"
                            class="my-1"
                            small
                            dark
                          >
                            Reject
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title>Reason For Rejection</v-card-title>
                          <v-divider></v-divider>
                          <v-card-text class="py-2">
                            <v-textarea v-model="reason"> </v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="primary-color" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn
                              class="secondary-color"
                              text
                              @click="updateStatus(item.id, 'Rejected')"
                              >Submit
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <!--                    <v-btn v-else color="success" small>{{-->
                    <!--                      item.status-->
                    <!--                    }}</v-btn>-->
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search3: null,
      reason: null,
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.map((item) => ({
        ...item,
        order_id: item.custom_order && item.custom_order.code,
        name: item.custom_order.shipping.name,
        total_price: item.qty * item.buying_unit_price,
        product_name: item.pname,
        variation: this.filterVariation(item.variation),
        quantity: item.qty,
        unit_price: item.buying_unit_price,
        created_at: moment(item.created_at).format("YYYY/MM/DD"),
        updated_at: moment(item.updated_at).format("YYYY/MM/DD"),
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions(["updateCustomOrderStatus"]),

    updateStatus(id, status) {
      let temp = this.orders.filter((value) => value.id === id)[0];
      this.editedIndex = this.orders.indexOf(temp);
      let data;

      if (status === "Rejected") {
        data = { id: id, status: status, reason_for_cancellation: this.reason };
      } else {
        data = { id: id, status: status };
      }
      this.updateCustomOrderStatus(data).then((response) => {
        this.$store.commit("setCustomStatus", response.data);
        Object.assign(temp, response.data);
        this.reason = null;
      });
      this.dialog = false;
      this.product = {};
      this.reason = null;
    },
    close() {
      this.dialog = false;
    },

    filterVariation(val) {
      if (val) {
        const color =
          val.colors && val.colors ? "Color: " + val.colour.name : "";
        const size = val.size && val.size ? "Size: " + val.sizes.code : "";
        const type = val.type && val.type !== "null" ? "Type: " + val.type : "";
        const weight = val.weight ? "Color: " + val.weight : "";
        return color + " " + size + " " + type + " " + weight;
      } else {
        return "No Variation";
      }
    },
  },
};
</script>
