var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',[(_vm.orders)?_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pt-0"},[_vm._v(_vm._s(_vm.type)+" Orders "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search3),callback:function ($$v) {_vm.search3=$$v},expression:"search3"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredOrders,"search":_vm.search3},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'image' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"color":"error","small":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" See Image ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Featured Image")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('v-img',{attrs:{"src":'https://dysgeklraeqfx.cloudfront.net/products/' +
                            item.pslug +
                            '/featured/' +
                            item.img}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-color",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center"},[(_vm.type === 'Pending')?_c('div',{staticClass:"my-2"},[(item.status === 'Confirmed')?_c('v-btn',{staticClass:"my-2",attrs:{"color":"success","small":"","dark":""},on:{"click":function($event){return _vm.updateStatus(item.id, 'Ready')}}},[_vm._v(" Ready ")]):_c('v-btn',{staticClass:"my-2",attrs:{"color":"success","small":"","dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1):(_vm.type === 'Active')?_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"my-1",attrs:{"color":"success","small":"","dark":""},on:{"click":function($event){return _vm.updateStatus(item.id, 'Confirmed')}}},[_vm._v(" Confirm ")]),_c('v-dialog',{attrs:{"id":'rejectDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"color":"error","small":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Reject ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Reason For Rejection")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('v-textarea',{model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-color",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"secondary-color",attrs:{"text":""},on:{"click":function($event){return _vm.updateStatus(item.id, 'Rejected')}}},[_vm._v("Submit ")])],1)],1)],1)],1):_vm._e()])]}}],null,false,3775980307)})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }