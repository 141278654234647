<template>
  <v-container fluid class="py-12">
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-van-utility</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Completed Orders</h5>
            <h2>{{ completedCustomOrders.length }}</h2>
          </div>
        </v-card>
      </v-col>
       <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-van-utility</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Active Orders</h5>
            <h2>{{ activeCustomOrders.length }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-van-utility</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Pending Orders</h5>
            <h2>{{ pendingCustomOrders.length }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-van-utility</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Cancelled Orders</h5>
            <h2>{{ cancelledCustomOrders.length }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-van-utility</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Orders</h5>
            <h2>{{ count }}</h2>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>Customer Orders</v-card-title>
          <v-card-text>
            <v-card>
              <v-tabs
                background-color="deep-purple accent-4"
                dark
                v-model="tab"
              >
                <v-tab> Active Orders </v-tab>
                <v-tab> Pending Orders </v-tab>
                <v-tab> Cancelled Orders </v-tab>
                <v-tab> Completed Orders </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <custom-order-tab-item
                  v-if="activeCustomOrders"
                  :headers="headers"
                  type="Active"
                  :orders="activeCustomOrders"
                ></custom-order-tab-item>
                <custom-order-tab-item
                  type="Pending"
                  :headers="headers"
                  :orders="pendingCustomOrders"
                ></custom-order-tab-item>
                <custom-order-tab-item
                  type="Cancelled"
                  :headers="cancelled_headers"
                  :orders="cancelledCustomOrders"
                ></custom-order-tab-item>
                <custom-order-tab-item
                  type="Completed"
                  :headers="completed_headers"
                  :orders="completedCustomOrders"
                ></custom-order-tab-item>
              </v-tabs-items>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CustomOrderTabItem from "../../components/orders/CustomOrderTabItem.vue";

export default {
  components: { CustomOrderTabItem },
  data() {
    return {
      search: "",
      search1: "",
      search2: "",
      search3: "",
      tab: null,
      headers: [
        { text: "Order Id", value: "order_id", align: "start" },
        {
          text: "Customer Name",
          sortable: false,
          value: "name",
        },
        {
          text: "Product Name",
          sortable: false,
          align: "center",
          value: "product_name",
        },
        {
          text: "Product Image",
          sortable: false,
          align: "center",
          value: "image",
        },
        {
          text: "Variation",
          sortable: false,
          align: "center",
          value: "variation",
        },

        {
          text: "Quantity",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Unit Price",
          sortable: false,
          value: "unit_price",
        },
        {
          text: "Total Price",
          sortable: false,
          value: "total_price",
        },
        { text: "Ordered Date", value: "created_at" },
        { text: "Status Changed", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      cancelled_headers: [
        { text: "Order Id", value: "order_id", align: "start" },
        {
          text: "Customer Name",
          sortable: false,
          value: "name",
        },
        {
          text: "Product Name",
          sortable: false,
          value: "product_name",
        },
        {
          text: "Product Image",
          sortable: false,
          align: "center",
          value: "image",
        },
        {
          text: "Variation",
          sortable: false,
          align: "center",
          value: "variation",
        },

        {
          text: "Quantity",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Unit Price",
          sortable: false,
          value: "unit_price",
        },
        {
          text: "Total Price",
          sortable: false,
          value: "total_price",
        },
        { text: "Ordered Date", value: "created_at" },
        { text: "Cancelled Date", value: "created_at" },
      ],
      completed_headers: [
        { text: "Order Id", value: "order_id", align: "start" },
        {
          text: "Customer Name",
          sortable: false,
          value: "name",
        },
        {
          text: "Product Name",
          sortable: false,
          value: "product_name",
        },
        {
          text: "Product Image",
          sortable: false,
          align: "center",
          value: "image",
        },
        {
          text: "Variation",
          sortable: false,
          align: "center",
          value: "variation",
        },

        {
          text: "Quantity",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Unit Price",
          sortable: false,
          value: "unit_price",
        },
        {
          text: "Total Price",
          sortable: false,
          value: "total_price",
        },
        { text: "Ordered Date", value: "created_at" },
        { text: "Delivered Date", value: "updated_at" },
      ],
    };
  },
  computed: {
    ...mapState({
      count: (state) => state.customOrders.length,
    }),

    ...mapGetters({
      pendingCustomOrders: "getpendingCustomOrders",
      activeCustomOrders: "getActiveCustomOrders",
      completedCustomOrders: "getcompletedCustomOrders",
      cancelledCustomOrders: "getcancelledCustomOrders",
    }),
  },
};
</script>
<style lang="scss">
.dashboard-card-inner {
  background: rgb(255, 34, 65) !important;
  top: -1.5rem;
  left: 1rem;
  position: absolute;
}
</style>
